import React from 'react'
import { AccessibilityState, getContrastClassName } from '../Menu';
import { useOutletContext } from 'react-router-dom';
import { Image } from "react-bootstrap";
import {Breadcrumbs} from "./Breadcrumbs";

export interface PageHeaderWithIconProps {
    navigation: string;
    header: string;
    iconSrc?: string;
}

export const PageHeaderWithIcon: React.FC<PageHeaderWithIconProps> = ({ navigation, header, iconSrc}) => {
    const contextOutlet: AccessibilityState = useOutletContext() 
    const { showAccessibilityOptions, fontScale, contrast, showImages
        , setFontScale, setContrast, setShowAccessibilityOptions, setShowImages
       } = contextOutlet;

  return (
    <section className='lead-header'>
        <div className='main_container'>
            <div>
                <Breadcrumbs />
                <h1>{header}</h1>
            </div>

            {(fontScale == 100 && getContrastClassName(contrast) == '' && showImages && iconSrc) && <Image src={iconSrc} />}
        </div>
    </section>
  )
}
