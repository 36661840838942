import {useOutletContext, useParams} from "react-router-dom";
import { AccessibilityState, getContrastClassName } from '../Menu';
import Footer from '../Footer';
import { PageHeaderWithIcon, RectangleCardGrid } from '../shared';
import { useEffect, useState } from "react";
import axios from "axios";

export interface ConferencePageProps {
    id: number; 
    title: string;
    subTitle?: string;
    startDate: string; 
    endDate?: string;
    description: string[]; 
    target: string; 
    directions: string[];
    image: string; 
    website?: string;
    program: string; 
    corporateDemographicStandard: string; 
    sociologicalResearch: string;
    finalCollection: string; 
    resolution: string;
    conferenceResults?: string;
    photoReport: string;
}

export function ConferencePage() {
    const contextOutlet: AccessibilityState = useOutletContext() 
    const { showAccessibilityOptions, fontScale, contrast, showImages
      , setFontScale, setContrast, setShowAccessibilityOptions, setShowImages
     } = contextOutlet;
     const { id } = useParams(); // Извлекаем параметр из URL
     const [conference, setConference] = useState<ConferencePageProps>();
     const [loading, setLoading] = useState(true);
     const [error, setError] = useState<string>('');
     
     const API_BASE_URL = window.config?.API_BASE_URL ?? 'http://localhost:8080';
 
     useEffect(() => {
         const fetchConferenceID = async () => {
             try {
                 const response = await axios.get(`${API_BASE_URL}/api/conferences/${id}`);
                 setConference(response.data); // Получаем записи из ответа
                 } catch (err) {
                 if (axios.isAxiosError(err)) {
                 // Это ошибка Axios
                 setError(`Ошибка при загрузке данных: ${err.response?.data?.message || err.message}`);
                 } else {
                 // Любая другая ошибка
                 setError('Ошибка при загрузке данных');
                 }
             } finally {
                 setLoading(false);
             }
         };
 
         fetchConferenceID();
     }, []); 

         // Проверяем, если conference не загружена (null)
    if (!conference) {
        return <p>Конференция не найдена.</p>; // Можно отображать сообщение об ошибке или как-то обрабатывать это состояние
    }
     
     const docs = [
        {
            id: 1,
            name: "Программа конференции",
            url: conference.program
        },
        { 
            id: 2,
            name: "Корпоративный демографический стандарт", 
            url: conference.corporateDemographicStandard
        },
        {
            id: 3,
            name: "Социологическое исследование",
            url: conference.sociologicalResearch
        },
        {
            id: 4,
            name: "Итоговый сборник",
            url: conference.finalCollection
        },
        { 
            id: 5,
            name: "Резолюция", 
            url: conference.resolution
        },
         {
             id: 6,
             name: "Фотоочет",
             url: conference.photoReport
         },
        //{ 
        //    id: 6,
        //    name: "Итоги конференции", 
        //    url: conference.conferenceResults
        //}
    ];

    const paragraphs = [
        ...(conference.website ? [{
            text: 'Сайт конференции: ',
            html: `<a href="https://www.${conference.website}" class="link">${conference.website}</a>`,
            onClick: () => window.open(`https://www.${conference.website}`, '_blank'),
          }] : []),
        ...docs.map(({ name, url }) => {
        return { 
            text: name,
            onClick: () => window.open(url, '_blank'),
            className: 'save_doc',
        };
    })];

    const cards = [
        {
            className: 'card-rectangle blue-rectangle',
            title: 'Подробнее',
            imageSrc: '/icons/doc.svg',
            paragraphs: paragraphs
        },
    ]  

    return(
        <div>
            <div style={{ fontSize: fontScale + '%' }} className={`${getContrastClassName(contrast)} page-wrapper`}>

                <PageHeaderWithIcon
                    header={conference.title}
                    navigation={`Главная / Конференции / ${conference.title}`}
                />

                <section className='section_light' style={{ border: 'none' }}>
                    <div className='main_container' style={{ paddingBottom: '0' }}>
                        <img src={conference.image} alt={"Фото "} className="conference-image" />
                        <br/><br/>
                        {conference.description.map((descript) => <p>{descript}</p>)}
                        <br/>
                        <p><b>Цель</b></p>
                        <p>{conference.target}</p>
                        <br/>
                        <p><b>Направления конференции</b></p>
                        <ul>{conference.directions.map((direction, index) => <li key={index}>{direction}</li>)}</ul>
                    </div>
                </section>       

                <section className="card-section">
                    <div className="main_container">
                        <RectangleCardGrid cards={cards} fullWidth />
                    </div>
                </section>

                <Footer/>
            </div>
        </div>
    )
}