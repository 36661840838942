import { createBrowserRouter } from "react-router-dom";
import Menu from "./components/Menu";
import HomePage from "./components/HomePage";
import Structure from "./components/Structure";
import BasicInfo from "./components/BasicInfo";
import Document from "./components/Document";
import Education from "./components/Education";
import InternationalCooperation from "./components/InternationalCooperation";
import AccessibleEnvironment from "./components/AccessibleEnvironment";
import FinancialActivity from "./components/FinancialActivity";
import Equipment from "./components/Equipment";
import PaidService from "./components/PaidService";
import PlacesAdmission from "./components/PlacesAdmission";
import Managers from "./components/Managers";
import Conferences from "./components/Conferences";
import { ConferencePage } from "./components/shared/ConferencePage";

export type CustomHandle = {
    handle?: {
        breadcrumb?: string | ((params: Record<string, string>, state: { name?: string | null } | null) => string);
    };
};

const router = createBrowserRouter(
    [
        {
            path: "/",
            element: <Menu />,
            handle: { breadcrumb: "Главная" } as CustomHandle,
            children: [
                {
                    path: "/",
                    element: <HomePage />,
                    handle: { breadcrumb: "Главная" },
                    index: true
                },
                {
                    handle: { breadcrumb: "Об организации" } as CustomHandle,
                    children: [
                        {
                            path: "organization",
                            element: <BasicInfo />,
                            handle: { breadcrumb: "Основные сведения" } as CustomHandle,
                        },
                        {
                            path: "structure",
                            element: <Structure />,
                            handle: { breadcrumb: "Структура и органы управления образовательной организацией" } as CustomHandle,
                        },
                        {
                            path: "document",
                            element: <Document />,
                            handle: { breadcrumb: "Документы" } as CustomHandle,
                        },
                        {
                            path: "education",
                            element: <Education />,
                            handle: { breadcrumb: "Образование" } as CustomHandle,
                        },
                        {
                            path: "paidservice",
                            element: <PaidService />,
                            handle: { breadcrumb: "Платные образовательные услуги" } as CustomHandle,
                        },
                        {
                            path: "placesadmission",
                            element: <PlacesAdmission />,
                            handle: { breadcrumb: "Вакантные места для приёма (перевода) обучающихся" } as CustomHandle,
                        },
                    ]
                },
                //{
                //    path: "/guide",
                //    element: <Guide />,
                //},
                {
                    path: "managers",
                    element: <Managers />,
                    handle: { breadcrumb: "Руководство" } as CustomHandle,
                },
                {
                    handle: { breadcrumb: "Об организации" } as CustomHandle,
                    children: [
                        {
                            path: "equipment",
                            element: <Equipment />,
                            handle: { breadcrumb: "Материально-техническое обеспечение и оснащённость образовательного процесса" } as CustomHandle,
                        },
                        {
                            path: "financialactivity",
                            element: <FinancialActivity />,
                            handle: { breadcrumb: "Финансово-хозяйственная деятельность" } as CustomHandle,
                        },
                        {
                            path: "accessibleenvironment",
                            element: <AccessibleEnvironment />,
                            handle: { breadcrumb: "Доступная среда" } as CustomHandle,
                        },
                        {
                            path: "internationalcooperation",
                            element: <InternationalCooperation />,
                            handle: { breadcrumb: "Международное сотрудничество" } as CustomHandle,
                        },
                    ]
                },
                {
                    path: "conferences",
                    handle: { breadcrumb: "Конференции" } as CustomHandle,
                    children: [
                        {
                            element: <Conferences />,
                            index: true
                        },
                        {
                            path: ":id",
                            element: <ConferencePage />,
                            handle: {
                                breadcrumb: (params: { id: any; }, state: { name: any; }) => {
                                    return state?.name || `Конференция`;
                                },
                            } as CustomHandle,
                        },
                    ]
                }
            ]
        }
    ]
);

export default router