import {Link, useLocation, useMatches} from "react-router-dom";
import {CustomHandle} from "../../Router";
import {ReactElement, useMemo} from "react";
import {clsx} from "clsx";
import styles from "./Breadcrumbs.module.scss";

export const Breadcrumbs = () => {
    const matches = useMatches();
    const location = useLocation();
    const state = location.state as { name?: string | null };

    const items: ReadonlyArray<ReactElement> = useMemo(() => {
        const result = matches
            .map((item) => {
                const handle = item as CustomHandle | undefined;
                if (handle?.handle?.breadcrumb != null) {
                    const breadcrumb =
                        typeof handle?.handle?.breadcrumb === "function"
                            ? handle?.handle.breadcrumb(item.params as Record<string, string>, state)
                            : handle?.handle?.breadcrumb;

                    return <li><Link to={item.pathname}>{breadcrumb}</Link></li>;
                }
                return undefined;
            })
            .filter((item): item is ReactElement => item !== undefined);

        return result;
    }, [matches, state]);

    return (
        <ul className={clsx(styles.root, "")}>
            {items}
        </ul>
    );
};